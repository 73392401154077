import React, { useContext } from "react"
import { toast } from "react-toastify"
import Button from "react-bootstrap/Button"
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd"
import lessonModel, { Instruction, Question, Section } from '../../../shared/lessons'
import LessonInstruction from "./LessonInstruction"
import LessonQuestion from "./LessonQuestion"
import EditButton from "./EditButton"
import TaskForm from "./TaskForm"
import { LessonContext, LessonBodyContext } from "../LessonContext"
import OpenCanvasButton from "components/Assignment/components/OpenCanvasButton"
import useBoolean from "helpers/useBoolean"

type TasksProps = {
    section: Section
    index: number
    setCurrentSection: React.Dispatch<React.SetStateAction<number>>
    saveLesson: ({ force }?: { force?: boolean }) => void
}

function Tasks({section, index, setCurrentSection, saveLesson}: TasksProps) {
    const { checkpoint, setCheckpoint, lessonPath, sections, setSections, refreshSections} = useContext(LessonBodyContext)
    const { lesson } = useContext(LessonContext)

    const editing = process.env.REACT_APP_EDITING === "true"
    const embeddedQuestions = section?.content[0]?.category === "Instruction"
    const [stopDraggable, toggleDraggable] = useBoolean(false)

    const transformQuestionIndex = (index: number) => {
        return embeddedQuestions ?
            String.fromCharCode(64 + index)
            : index.toString()
    }

    const dragEnd = async (result: DropResult) => {
        if (!result.destination) {
            return
        }
        const startIndex = result.source.index
        const endIndex = result.destination.index
        if (startIndex === endIndex) {
            return
        }
        const newSections = [...sections]
        const [movedItem] = newSections[index].content.splice(startIndex, 1)
        let formData = new FormData()
        formData.append("lessonId", lesson.id)
        formData.append("sectionNumber", (index + 1).toString())
        formData.append("listNumber", movedItem.index.toString())
        formData.append("newListNumber", endIndex + 1)
        for (let key in movedItem) {
            formData.append(key, movedItem[key])
        }
        newSections[index].content.splice(endIndex, 0, movedItem)
        newSections[index].content.forEach((task, i: number) => {
            task.index = i + 1
        })
        setSections(newSections)
        await lessonModel.editTask(formData)
        refreshSections() // don't wait for refresh since it will lag. Will call setSections again when refresh is done.
    }

    return(<>
        <h2 className="h5 text-start fw-bold py-2">{index === 1 ? "What Do You Understand?" : section.title}</h2>
        {/* Ric wants all questions in section 2 to have same title. Commenting out in case he wants to change his mind */}
        {/*
        <EditButton text="Edit Section Title" editCallback={async (values) => {
            await lessonModel.editSection(lesson.id, index + 1, values.title)
            refreshSections()
        }}>
            <div className="mb-4">
                <h2 className="h5">
                    Section #{index + 1} Title
                </h2>
                <Field
                    as={'textarea'}
                    name={"title"}
                    defaultValue={section.title}
                    className={`${styles.lessonInput} ${styles.lessonInputLg} w-75`}
                />
            </div>
        </EditButton>
        */}
        {editing && <>
                <Button onClick={() => {
                    lessonModel.addSection(lesson.id)
                    refreshSections()
                }} variant="default" className="btn-sm mx-2 mb-3">Add Section</Button>
                <Button onClick={() => {
                    lessonModel.deleteSection(lesson.id, index + 1)
                    if (index + 1 >= sections.length) {
                        setCurrentSection(index - 1)
                    }
                    refreshSections()
                }} variant="default" className="btn-sm mx-2 mb-3">Delete Section</Button>
        </>}
        <DragDropContext onDragEnd={dragEnd}>
            <Droppable droppableId={`Section ${index}`} >
                {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef} className="list-group list-group-flush text-start">
                        {section.content.map((task, i) => {
                            const questionNumEmbedded = section.content.filter((t, fi) => t.category === "Question" && fi <= i).length
                            return <Draggable draggableId={i.toString()} isDragDisabled={!editing || stopDraggable} index={i} key={i}>
                                {(provided) => (
                                    <div className={`${checkpoint + 1 >= task.index || 'text-muted'} list-group-item py-3`} key={i}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                    >
                                            {task.category === "Instruction" && <>
                                                <LessonInstruction
                                                    instruction={{ ...task as Instruction}}
                                                    sectionNumber={index + 1}
                                                    setCheckpoint={setCheckpoint}
                                                    instructionIndex={section.content.filter((t, fi) => t.category === "Instruction" && fi <= i).length.toString()}
                                                />
                                                <EditButton text="Edit Instruction" editCallback={async (values) => {
                                                    const newSections = [...sections]
                                                    let formData = new FormData()
                                                    formData.append("lessonId", lesson.id)
                                                    formData.append("sectionNumber", (index + 1).toString())
                                                    formData.append("listNumber", values.index)
                                                    for (let key in values) {
                                                        if (values[key] !== undefined) {
                                                            formData.append(key, values[key])
                                                        }
                                                    }
                                                    await lessonModel.editTask(formData)
                                                    newSections[index].content[i] = values
                                                    delete values.image
                                                    setSections(newSections)
                                                    refreshSections()
                                                }} reqBody={task} size="lg">
                                                    <TaskForm task={task} sectionNumber={index + 1} path={lessonPath} removeTask={async () => {
                                                        const {data, status} = await lessonModel.removeTask(lesson.id, index + 1, task.category, task.index)
                                                        if(status === 200) toast.success(data.message)
                                                        else toast.error(data.message)
                                                        const newSections = [...sections]
                                                        delete newSections[index].content[task.index - 1]
                                                        setSections(newSections)
                                                        refreshSections()
                                                    }} />
                                                </EditButton>
                                            </>}
                                            {task.category === "Question" && <>
                                                {task.isDrawing === "Yes" ? 
                                                    <OpenCanvasButton
                                                        lessonQuestion={{...task as Question}}
                                                        questionIndex={transformQuestionIndex(questionNumEmbedded)}
                                                        sectionNumber={index + 1}
                                                        saveLesson={saveLesson}
                                                        stopDraggable={toggleDraggable}
                                                    /> :
                                                    <LessonQuestion
                                                        question={{ ...task as Question }}
                                                        sectionNumber={index + 1}
                                                        questionIndex={transformQuestionIndex(questionNumEmbedded)}
                                                        questionNumber={i + 1}
                                                        saveLesson={saveLesson}
                                                    />
                                                }
                                                <EditButton text="Edit Question" editCallback={async (values) => {
                                                    const newSections = [...sections]
                                                    let formData = new FormData()
                                                    formData.append("lessonId", lesson.id)
                                                    formData.append("sectionNumber", (index + 1).toString())
                                                    formData.append("listNumber", values.index)
                                                    for (let key in values) {
                                                        if (values[key] !== undefined) {
                                                            formData.append(key, values[key])
                                                        }
                                                    }
                                                    await lessonModel.editTask(formData)
                                                    newSections[index].content[i] = values
                                                    delete values.image
                                                    setSections(newSections)
                                                    refreshSections()
                                                }} reqBody={task} size="lg">
                                                    <TaskForm task={task} sectionNumber={index + 1} path={lessonPath} removeTask={async () => {
                                                        await lessonModel.removeTask(lesson.id, index + 1, task.category, task.index)
                                                        const newSections = [...sections]
                                                        delete newSections[index].content[task.index - 1]
                                                        setSections(newSections)
                                                        refreshSections()
                                                    }} />
                                                </EditButton>
                                            </>}
                                        </div>
                                )}
                            </Draggable>}
                        )}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
        {editing && <>
            <Button onClick={async () => {
                const newSections = [...sections]
                newSections[index].content.push({
                    category: "Instruction",
                    index: section.content.length + 1,
                    content: "",
                    title: "",
                    imageAltText: null,
                    imageUpdated: null,
                    lessonNumber: lesson.number,
                    type: lesson.type
                })
                setSections(newSections)
                await lessonModel.addTask(lesson.id, index + 1, "Instruction")
                refreshSections()
            }} variant="default" className="btn-sm mx-2 mb-3">Add Instruction</Button>
            <Button onClick={async () => {
                const newSections = [...sections]
                newSections[index].content.push({
                    category: "Question",
                    index: section.content.length + 1,
                    content: "",
                    answer: "",
                    answerPosition: "Bottom",
                    imageAltText: null,
                    imageUpdated: null,
                    number: section.content.length + 1,
                    lessonNumber: lesson.number,
                    type: lesson.type
                })
                setSections(newSections)
                await lessonModel.addTask(lesson.id, index + 1, "Question")
                refreshSections()
            }} variant="default" className="btn-sm mx-2 mb-3">Add Question</Button>
        </>}
    </>)
}

export default Tasks
