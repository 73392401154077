import { AuthContext } from "AuthContext"
import React from "react"
import Loader from "react-spinners/BeatLoader"
import { useModel } from "@stem-sims/nexus"
import assignmentsModel from "shared/routes/assignments"
import styles from './assessment.module.scss'
import assessments from "shared/routes/assessments/assessments"
import moduleRoutes from "shared/routes/moduleRoutes"
import { DrawingAnswer } from "shared/types/studentTypes"
import AssessmentQuestion from "./AssessmentQuestion"

interface AssessmentProps {
  assessmentId: string
  moduleName: string
  assessmentNumber: number
  type: string
  initialAssessmentData?: Record<string, string>
  initialAssessmentDrawingData?: Record<string, DrawingAnswer>
  setDrawingResponses: React.Dispatch<React.SetStateAction<{}>>
  isPartOfTopic: boolean
  debouncedSaveAssessment?: () => Promise<void>
}


const Assessment = (props: AssessmentProps) => {
  const { assessmentId, initialAssessmentData, initialAssessmentDrawingData } = props
  const authInformation = React.useContext(AuthContext)
  const editing = process.env.REACT_APP_EDITING === "true"

  const formRef = React.useRef(null)

  const { response: assessmentQuestions, loading, refreshModel } = useModel({
    model: assignmentsModel.getAssessmentQuestions,
    props: { assessmentId }
  })

  const { response: assessment, loading: loadingAssessment } = useModel({
    model: assessments.findById,
    props: { id: assessmentId }
  })

  React.useEffect(() => {
    formRef.current?.reset()
  }, [initialAssessmentData])

  const formattedModuleName = assessment?.lessonId ?
  `Lesson ${assessment.lessonNumber} Assessment: ${props.moduleName}` :
  `Assessment ${assessment?.number}: ${props.moduleName}`

  React.useEffect(() => {
    const updateModuleHit = async (moduleName: string) => {
        await moduleRoutes.updateModuleHit(moduleName)
    }

    if (authInformation.isLoggedIn && authInformation.isStudent) {
        updateModuleHit(props.moduleName)
    }
  }, [authInformation.isLoggedIn, authInformation.isStudent, props?.moduleName])

  return (
    <form className={styles.assessment} id="assessment-form" ref={formRef}>
      { editing && <a href={`/editing/simulations/assessment?&module=${props.moduleName}&assessmentNumber=${props.assessmentNumber}&type=${props.type}`}>Old Assessment Page For Editing</a> }
        {loadingAssessment ?
        <Loader color={"#007bff"} /> :
        <h1 className={"h4 fw-bold"}>
            {formattedModuleName}
        </h1>}
        <div className="mt-4">
            {loading && !assessmentQuestions && <Loader color={"#007bff"} />}
        </div>
        {assessmentQuestions?.map((question, index) => (
          <AssessmentQuestion
            key={`${question.question} - ${index}`}
            assessmentId={assessmentId}
            assessmentNumber={props.assessmentNumber}
            index={index}
            initialAnswerData={question.isDrawing === "Yes" ? initialAssessmentDrawingData?.[`question-${question.number}`] : initialAssessmentData?.[`question-${question.number}`]}
            moduleName={props.moduleName}
            question={question}
            saveAssessment={props.debouncedSaveAssessment}
            setDrawingResponses={props.setDrawingResponses}
            type={props.type}
            refreshQuestions={refreshModel}
          />
        ))}
    </form>
)
}

export default Assessment
