import { Row, Col, Image } from "react-bootstrap"
import { DrawingAnswer } from "shared/types/studentTypes"
import FullScreenWrapper from "./FullScreenWrapper"
import styles from "./DrawingQuestion.module.scss"
import { convertImgToBase64 } from "../helpers/drawingHelper"
import { BeatLoader } from "react-spinners"

interface DrawingQuestionImportProps {
    openImport: boolean
    toggleImport: () => void
    drawingAnswers: DrawingAnswer[]
    copyDrawing: (answer: DrawingAnswer) => void
}

export default function DrawingQuestionImport(props: DrawingQuestionImportProps) {
    const { openImport, drawingAnswers, toggleImport, copyDrawing } = props
    return <>
        {openImport &&
            <FullScreenWrapper fullScreen={openImport}>

                <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                    <span>Choose the drawing to import.</span>
                    <i role="button" className={`fas fa-close iconButton me-2 py-1 text-dark`} onClick={() => {
                        toggleImport()
                    }} />
                </div>

                {!drawingAnswers &&
                    <div className="d-flex flex-row justify-content-center">
                        <BeatLoader size={20} />
                    </div>
                }

                <Row>
                    {drawingAnswers?.map((answer, index) => {
                        if (!answer.answer) {
                            return null
                        } else {
                            return <Col xs={3} className="text-center align-middle mb-2" style={{ maxHeight: "20vh" }} key={`${answer.answer}-${index}`}>
                                <Image
                                    role='button'
                                    src={answer.drawingResponse}
                                    alt={'Student Drawing Answer'}
                                    className={`rounded text-center shadow-sm border border-1 ${styles.importImage} center-block p-1`}
                                    onClick={async () => {
                                        convertImgToBase64(answer.drawingResponse).then((base64Img) => {
                                            const caliState: { elements: Array<{ type: string }> } = JSON.parse(answer.answer)
                                            caliState.elements = caliState.elements.filter((element) => element.type !== "image")
                                            copyDrawing({
                                                answer: JSON.stringify(caliState),
                                                drawingResponse: base64Img,
                                            })
                                        })
                                    }}
                                />
                            </Col>
                        }
                    })}
                </Row>

            </FullScreenWrapper>
        }
    </>
}
