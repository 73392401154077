import { useEffect } from "react"
interface FullScreenWrapperProps {
    children: any
    fullScreen: boolean
}

export default function FullScreenWrapper({ children, fullScreen }: FullScreenWrapperProps) {

    useEffect(() => {
        if(fullScreen) {
            document.body.classList.add('full-screen-mode')
        } else {
            document.body.classList.remove('full-screen-mode')
        }
        return( () => document.body.classList.remove('full-screen-mode'))

    }, [fullScreen])

    return <div className={`${fullScreen ? "full-screen-mode" : ""} inherit-flex`}>
        <div className={`${fullScreen ? "container h-100 py-4" : ""} inherit-flex`}>
            {children}
        </div>
    </div>
}

