import DrawingQuestion from "components/Assignment/components/DrawingQuestion"
import { Button, Form } from "react-bootstrap"
import { CurriculumQuestion, TopicStudentAnswers, CurriculumSection } from "shared/types/curriculumTypes"
import styles from "../../../components/Lessons/LessonModule/styles.module.scss"
import assignmentStyles from "../../../components/Assignment/assessment.module.scss"
import DrawingQuestionImport from "components/Assignment/components/DrawingQuestionImport"
import { getSubmissionDrawingQuestions } from "shared/routes/assignments"
import { useContext, useState } from "react"
import { AssignmentContext } from "components/Assignment/AssignmentContext"
import useBoolean from "helpers/useBoolean"
import React from "react"
import { DrawingAnswer } from "shared/types/studentTypes"

interface SectionQuestionInputProps {
    question: CurriculumQuestion
    sectionNumber: number
    studentAnswers: TopicStudentAnswers
    initialStudentAnswers: TopicStudentAnswers
    currentSection: CurriculumSection
    handleAnswerChange: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
}

export default function SectionQuestionInput({ question, sectionNumber, studentAnswers, initialStudentAnswers, currentSection, handleAnswerChange }: SectionQuestionInputProps) {

    const { submissionId } = useContext(AssignmentContext)

    const [openImport, toggleImport] = useBoolean(false)
    const [drawingAnswers, setDrawingAnswers] = useState<DrawingAnswer[]>(null)

    React.useEffect(() => {
        if (!submissionId || !openImport) {
            setDrawingAnswers(null)
            return
        }

        getSubmissionDrawingQuestions({ submissionId }).then((newDrawingAnswers) => {
            setDrawingAnswers(newDrawingAnswers ?? [])
        })
    }, [submissionId, openImport])

    const [copiedDrawing, setCopiedDrawing] = useState<DrawingAnswer>(null)

    const copyDrawing = (answer: DrawingAnswer) => {
        toggleImport()
        setCopiedDrawing(answer)
        handleAnswerChange(sectionNumber, question?.sortOrder, answer.answer, answer.drawingResponse)
    }

    if (!question) {
        return null
    }

    if (question.answers?.length > 1) {
        return <>{question?.answers?.map((answer, answerIndex) => (
            <div
                key={answerIndex}
                className={`${assignmentStyles.answerOption} text-start`}
            >
                <input
                    type="radio"
                    id={`s${sectionNumber}-q${question.sortOrder}a${answerIndex}`}
                    name={`s${sectionNumber}-q${question.sortOrder}`}
                    value={answer.letter}
                    checked={studentAnswers[`s${sectionNumber}-q${question.sortOrder}`]?.answer === answer.letter}
                    onChange={() => {
                        handleAnswerChange(sectionNumber, question.sortOrder, answer.letter, null)
                    }}
                />
                <label htmlFor={`s${sectionNumber}-q${question.sortOrder}a${answerIndex}`} className="ms-1">
                    <span className='me-2'>{answer.letter}.</span>
                    <span dangerouslySetInnerHTML={{ __html: answer.answer }} />
                </label>
            </div>
        ))}</>
    } else if (question.type === "Drawing") {
        return <>
            <DrawingQuestion
                questionType="topic"
                topicQuestion={question}
                initialTopicDrawingData={copiedDrawing ?? initialStudentAnswers?.[`s${currentSection.sortOrder}-q${question.sortOrder}`]}
                sectionNumber={currentSection.sortOrder}
                handleAnswerChange={handleAnswerChange}
            />

            <div className="d-flex justify-content-center">
                <Button className="mx-2" onClick={() => {
                    toggleImport()
                }}>
                    Import Drawing
                </Button>
            </div>

            <DrawingQuestionImport
                copyDrawing={copyDrawing}
                drawingAnswers={drawingAnswers}
                openImport={openImport}
                toggleImport={toggleImport}
            />
        </>
    } else if (question.type === "Table") {
        return null
    } else {
        return <Form.Control
            as="textarea"
            className={`${styles.lessonInput} input-glow ms-auto`}
            name={`s${sectionNumber}-q${question.sortOrder}`}
            value={studentAnswers[`s${sectionNumber}-q${question.sortOrder}`]?.answer}
            onChange={(e) => handleAnswerChange(sectionNumber, question.sortOrder, e.target.value, null)}
        />
    }
}
