import { checkEachTableContent, groupTablesByTableNumbers, parseFormData } from "helpers/formDataHelper"
import { highlight, removeHighlight } from "helpers/htmlElementHighlight"
import { LessonResponse } from "shared/lessons"
import modules from "shared/routes/moduleRoutes"
import pureInquiry from "shared/routes/simulations/pureInquiry"
import { DateString } from "shared/types/commonTypes"
import { Type } from "shared/types/moduleTypes"
import { DrawingAnswer } from "shared/types/studentTypes"


type SaveGuidedLessonProps = {
    lesson: LessonResponse
    completed: boolean
    isTeacher: boolean
    lessonFormRef: any
    studentLessonIdRef?: any
    submissionId?: string
    drawingResponses?: Record<string, DrawingAnswer>
    allowEmpty?: boolean
}


export const saveGuidedLesson = async ({ lesson, completed, isTeacher, lessonFormRef, studentLessonIdRef, submissionId, drawingResponses, allowEmpty }: SaveGuidedLessonProps): Promise<unknown> => {
    if (isTeacher) {
        return
    }
    const parameters = {
        moduleName: lesson.moduleName,
        lessonType: lesson.type,
        lessonNumber: lesson.number,
        submissionId: submissionId,
        formData: {...parseFormData(lessonFormRef.current, true), ...drawingResponses},
        completed: completed,
        allowEmpty: allowEmpty,
        studentLessonId: studentLessonIdRef?.current ?? undefined
    }

    const newLessonSubmissionId = await modules.submitLesson(parameters)
    if (newLessonSubmissionId.data && studentLessonIdRef) {
        studentLessonIdRef.current = newLessonSubmissionId.data // newly created studentLesson Id is returned unless there was no data to save
    }
}

interface SaveTopicProps {
    topicId: string
    submissionId: string
    studentAnswers: any
    startTime: DateString
    isCompleted: boolean
    prevSavedRef: React.MutableRefObject<boolean>
}

export const saveTopic = async ({ topicId, studentAnswers, submissionId, startTime, isCompleted, prevSavedRef } : SaveTopicProps) => {
    if (prevSavedRef.current) {
        const parameters = {
            topicId,
            submissionId,
            isCompleted,
            putParams: studentAnswers
        }
        await modules.updateTopic(parameters)
    } else {
        const parameters = {
            topicId,
            submissionId,
            startTime,
            isCompleted,
            postParams: studentAnswers
        }
        const res = await modules.submitTopic(parameters)
        if (res.valid) {
            prevSavedRef.current = true
        }
    }
}

type SavePureInquiryLessonProps = {
    moduleName: string,
    type: Type,
    completed: boolean
    isTeacher: boolean
    questionAnswers: string[]
    tableAnswers: string[]
    started: any
    prevSavedRef: any
    submissionId?: string
}
export const savePureInquiryLesson = ({ moduleName, type, completed, isTeacher, questionAnswers, tableAnswers, started, prevSavedRef, submissionId }: SavePureInquiryLessonProps): Promise<unknown> => {
    if (isTeacher) {
        return
    }
    const parameters = {
        submissionId: submissionId,
        module: moduleName,
        type: type,
        started: started,
        questionAnswers: questionAnswers,
        tableAnswers: tableAnswers,
        completed: completed,
    }
    
    if (prevSavedRef.current) {
        return pureInquiry.put(parameters)
    } else {
        prevSavedRef.current = true
        return pureInquiry.post(parameters)
    }
}

export const answerLengths = {
    "Tier 1": 8,
    "Tier 2": 10,
    "AP Tier": 11
}

// number of milliseconds to wait in between trying to save lesson
export const saveLessonInterval = 5000

export const highlightIncompleteLessonQuestions = (studentLessonAnswers: unknown) => {
    let highlightTaskTab = false
    let highlightQuestionsTab = false

    const tableData = {}

    for (const key of Object.keys(studentLessonAnswers)) {
       const isQuestionKey = key.match(/^question-\d+-\d+$/)
       const isTableKey = key.match(/^table-\d+-\d+$/)

       if (!isQuestionKey && !isTableKey) continue

       if (isQuestionKey) {
           const sectionNumber = parseInt(key.split("-")[1])
    
           if (typeof studentLessonAnswers[key] === "string") {
    
                // highlight/remove text questions
                if (studentLessonAnswers[key] === "") {
                    highlight(key)
    
                    if (sectionNumber === 1) {
                        highlightTaskTab = true
                    }
    
                    if (sectionNumber === 2) {
                        highlightQuestionsTab = true
                    }
                } else {
                    removeHighlight(key)
                }
            } else {
    
                // highlight/remove drawing questions
                if (!studentLessonAnswers[key]?.answer) {
                    highlight(key)
    
                    if (sectionNumber === 1) {
                        highlightTaskTab = true
                    }
    
                    if (sectionNumber === 2) {
                        highlightQuestionsTab = true
                    }
                } else {
                    removeHighlight(key)
                }
            }
       } else {
            groupTablesByTableNumbers({key: key, value: studentLessonAnswers[key], tableData: tableData})
       }
    }

    const highlightTableTab = !checkEachTableContent(tableData) // highlight if any table has no input

    /**
     * After highlighting questions, highlight the tabs
     */
    const taskTab = document.getElementById("controlled-tab-example-tab-tasks") // button id inside the Tasks tab
    const questionsTab = document.getElementById("controlled-tab-example-tab-questions") // button id inside the Questions tab
    const tableTab = document.getElementById("controlled-tab-example-tab-table")

    if (highlightTaskTab) {
        taskTab.style.backgroundColor = "#ffc107" // this is bootstrap bg-warning color
    } else {
        taskTab.style.removeProperty("background-color")
    }

    if (highlightQuestionsTab) {
        questionsTab.style.backgroundColor = "#ffc107"
    } else {
        questionsTab.style.removeProperty("background-color")
    }

    if (highlightTableTab) {
        tableTab.style.backgroundColor = "#ffc107"
    } else {
        tableTab.style.removeProperty("background-color")
    }
}
