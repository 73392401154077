import React, { useCallback, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import AssignmentComponent from "../../Assignment/AssignmentComponent"
import { StudentAssignmentStateProps } from "shared/types/moduleTypes"
import { AssignmentContext, AssignmentSection } from "components/Assignment/AssignmentContext"
import moduleRoutes from "shared/routes/moduleRoutes"
import { startNewSubmission } from "helpers/getSubmissionId"
import { StudentAssignmentResponse } from "shared/types/studentTypes"

export class StudentAssignmentState implements StudentAssignmentStateProps {
    assignment: StudentAssignmentResponse
    continueAssessment: boolean
    submissionId: string
}

export default function StudentAssignment() {
    const location = useLocation()
    const state = location.state as StudentAssignmentStateProps
    const [onReturn, setOnReturn] = React.useState(null)

    let initActivePart;
    if(state.assignment.topicId) {
        initActivePart = "topic"
    } else if (state.assignment.lessonId || state.assignment.lessonVariety === "Pure Inquiry") {
        initActivePart = "lesson"
    } else {
        initActivePart = "assessment"
    }
    const [activePart, setActivePart] = React.useState<AssignmentSection>(initActivePart)
    const [continueAssessment, setContinueAssessment] = useState<boolean>(state.continueAssessment)
    const [submissionId, setSubmissionId] = useState<string>(state.submissionId ?? null)

    const navigate = useNavigate()
    const goBack = useCallback(() => navigate("/dashboard/student/assignments"), [navigate])

    React.useEffect(() => {
        moduleRoutes.checkModuleAccess(state.assignment.moduleName ?? undefined)
        .then(userHasAccess => {
            if (!userHasAccess) {
                return window.location.href = `/simulations/moduleName=${state.assignment.moduleName}/tab=challenge`
            }
        })
        if (location.state === null) {
            toast.info("Unable to load assignment, please reselect the assignment.")
            navigate("/dashboard/student/assignments")
            return null
        }
    }, [location.state, navigate, state.assignment.moduleName, state.assignment.submissions])

    if (location.state === null) {
        return null
    }

    const restartAssignment = async () : Promise<void> => {
        const id = await startNewSubmission(state.assignment.id)
        setSubmissionId(id)
        setContinueAssessment(false)
    }

    const assignmentValues = {
        activePart: activePart,
        setActivePart: setActivePart,
        restartAssignment: restartAssignment,
        moduleName: state.assignment.moduleName,
        lessonVariety: state.assignment.lessonVariety,
        type: state.assignment.type,
        guidedLesson: state.assignment.lesson,
        assessmentOnly: initActivePart === "assessment",
        setOnReturn: setOnReturn,
        submissionId: submissionId,
    }

    const assessmentId = String(state.assignment?.assessmentId)
    const topicId = String(state.assignment?.topicId)

    return (
        <>
            <div className="text-start my-3">
                {(activePart !== "assessment" && activePart !== "completed") && <Link
                    style={{
                        "textDecoration": "none"
                    }}
                    to="/dashboard/student/assignments"
                    onClick={() => {
                        onReturn?.()
                    }}
                >
                    <i className="fas fa-arrow-left" /> Save and Return to Assignments
                </Link>}
            </div>
            <AssignmentContext.Provider value={assignmentValues}>
                <AssignmentComponent
                    assessmentId={assessmentId}
                    submissionId={submissionId}
                    topicId={topicId}
                    moduleName={state.assignment.moduleName}
                    assessmentNumber={state.assignment.assessmentNumber}
                    lessonVariety={state.assignment.lessonVariety}
                    type={state.assignment.type}
                    requireCompletion={state.assignment.requireCompletion}
                    goBack={goBack}
                    continueAssessment={continueAssessment}
                />
            </AssignmentContext.Provider>
        </>
    )
}
