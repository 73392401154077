import CustomHTML from 'components/CustomHTML'
import { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Question } from 'shared/lessons'
import DrawingQuestion from './DrawingQuestion'
import FullScreenWrapper from './FullScreenWrapper'
import styles from "./DrawingQuestion.module.scss"
import useBoolean from 'helpers/useBoolean'
import { AssignmentTopicContext } from '../AssignmentTopicContext'
import { LessonContext } from 'components/Lessons/LessonContext'
import { DrawingAnswer } from 'shared/types/studentTypes'
import { getSubmissionDrawingQuestions } from 'shared/routes/assignments'
import DrawingQuestionImport from './DrawingQuestionImport'
import { AssignmentContext } from '../AssignmentContext'

interface Props {
    lessonQuestion: Question
    questionIndex: string
    sectionNumber: number // only for lesson question
    saveLesson?: ({ force }?: { force?: boolean }) => void
    autoSave?: () => void
    stopDraggable: () => void
}

const OpenCanvasButton = ({ lessonQuestion, questionIndex, sectionNumber, saveLesson, autoSave, stopDraggable }: Props) => {
    const [openCanvas, setOpenCanvas] = useState<boolean>(false)

    const { setLessonDrawResponses } = useContext(AssignmentTopicContext)
    const { submissionId } = useContext(AssignmentContext)
    const { isPartOfTopic, setDrawingResponses } = useContext(LessonContext)

    const [openImport, toggleImport] = useBoolean(false)
    const [drawingAnswers, setDrawingAnswers] = useState<DrawingAnswer[]>(null)

    useEffect(() => {
        if (!submissionId || !openImport) {
            setDrawingAnswers(null)
            return
        }

        getSubmissionDrawingQuestions({ submissionId }).then((newDrawingAnswers) => {
            setDrawingAnswers(newDrawingAnswers ?? [])
        })
    }, [submissionId, openImport])

    const onOpen = () => {
        setOpenCanvas(true)
        stopDraggable()
    }

    const onMinimize = () => {
        setOpenCanvas(false)
        saveLesson({ force: true })
        stopDraggable()
    }

    const copyDrawing = (answer: DrawingAnswer) => {
        toggleImport()
        if (isPartOfTopic) {
            setLessonDrawResponses((prev) => ({
                ...prev,
                [`question-${sectionNumber}-${lessonQuestion.index}`]: answer,
            }))
        } else {
            setDrawingResponses(prev => ({ ...prev, [`question-${sectionNumber}-${lessonQuestion.index}`]: answer }))
            setOpenCanvas(true)
        }
    }
    const questionId = `question-${sectionNumber}-${lessonQuestion.index}` // used for highlighting incomplete question

  return (
    <div id={questionId}>
        <div className="pe-2">{questionIndex}. <CustomHTML html={lessonQuestion.content} /></div>
        <i className="incompleteExclamation d-none fas fa-exclamation-circle text-warning position-absolute" style={{top: '8px', left: '0'}} />
        {lessonQuestion.imageUpdated && <div>
            <img
                className={`${styles.lessonImage} img-fluid center-block p-1`} src={lessonQuestion.imagePath}
                alt={lessonQuestion.imageAltText ?? 'Question image'}
            />
        </div>}
        <div className="d-flex justify-content-center my-4">
            <Button onClick={onOpen}>
                Open Canvas
            </Button>

            <Button className="mx-2" onClick={() => {
                toggleImport()
                stopDraggable()
            }}>
                Import Drawing
            </Button>
        </div>
        {openCanvas && 
            <FullScreenWrapper fullScreen={openCanvas}>
                <DrawingQuestion 
                    questionType="lesson"
                    lessonQuestionIndex={questionIndex}
                    lessonQuestion={lessonQuestion}
                    sectionNumber={sectionNumber}
                    autoSave={autoSave}
                    minimizeCanvas={onMinimize} />
            </FullScreenWrapper> 
        }

            <DrawingQuestionImport
                copyDrawing={copyDrawing}
                drawingAnswers={drawingAnswers}
                openImport={openImport}
                toggleImport={() => {
                    toggleImport()
                    stopDraggable()
                }}
            />

        </div>
    )
}

export default OpenCanvasButton
