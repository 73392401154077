import { createContext } from "react"
import { DrawingAnswer } from "shared/types/studentTypes"

export type AssignmentTopicContextType = {
    refreshTopicSectionProgressCheck: () => void
    lessonDrawResponses?: Record<string, DrawingAnswer>
    setLessonDrawResponses?: React.Dispatch<React.SetStateAction<Record<string, DrawingAnswer>>>
    assessmentDrawResponses?: Record<string, DrawingAnswer>
    setAssessmentDrawResponses?: React.Dispatch<React.SetStateAction<Record<string, DrawingAnswer>>>
}

export const AssignmentTopicContext = createContext<AssignmentTopicContextType>({
    lessonDrawResponses: null,
    setLessonDrawResponses: null,
    assessmentDrawResponses: null,
    setAssessmentDrawResponses: null,
    refreshTopicSectionProgressCheck: null,
})
